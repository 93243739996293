<template>
 <v-sheet
     style="position: sticky; top : 0; z-index: 999; transition: all 0.3s ease ;
     width: 100%"
     :style="!SM || showMobileMenu ?  `border-bottom: 1px solid ${wsBACKGROUND}` : null"
     class=""
     :elevation="SM && showMobileMenu ? '5' : null"

 >

   <div class="d-flex justify-center  px-3">
     <!-- DESKTOP MENU-->
     <v-sheet height="64"
              class="d-flex justify-space-between align-center py-4" color="transparent" max-width="1140" width="1140" style="position: relative">


         <router-link @click="showMobileMenu = false" :to="localeLink('market')" class="pointer noUnderline">
           <div class="d-flex align-center">
           <img class="text-center pointer "
                @click="showMobileMenu = false"
                height="32"
                width="auto"
                alt="WeStudy"
                src="/library/img/westudy_logo.png"
           />
           <v-divider vertical class="mx-3" style="border-color: var(--wsBACKGROUND); border-width: 1px"></v-divider>
           <h4 class="wsATTENTION text-no-wrap " >
             Курс маркет
           </h4>
           </div>
         </router-link>




       <!-- DESKTOP-->
       <div v-if="!SM" style="width: 100%">
         <ws-text-field
              @change="searchCourses"
              @focus="focused = true"
              @blur="focused = false"
              :width="focused ? '100%' : '400px'"
              style="transition: all 0.1s ease"
              icon="mdi-magnify"
              class="px-8"
              :placeholder="$t('SearchСourses')"
              clearable
         />
       </div>


       <div v-if="!SM" class="d-flex align-center">
         <div v-if="!focused" class="d-flex align-center">
           <ws-lang-select :text-color="wsACCENT" class="mr-5" />
           <ws-button
               :to="localeLink('')"
               label="Стати автором"
               class="mr-3"
               outlined
           />
           <ws-button
               v-if="!$store.state.auth.loggedIn"
               :to="localeLink('login')"
               label="Login"
               class="mr-3"
           />
           <userDropdown dark v-else />
         </div>
         <ws-button
             v-else
             label="Search"
         />






       </div>
       <!-- MOBILE -->
       <v-btn @click="showMobileMenu = !showMobileMenu" v-if="SM" elevation="2" icon>
         <v-icon :color="wsACCENT">
           mdi-{{ !SM || (SM && !showMobileMenu) ? 'menu' : 'close' }}
         </v-icon>
       </v-btn>




     </v-sheet>
   </div>

   <v-fade-transition>
     <v-divider :style="`border-color : ${wsBACKGROUND}`" v-if="SM && showMobileMenu" />
   </v-fade-transition>


  <!-- MOBILE MENU-->
   <v-expand-transition>

     <v-sheet  class="" v-if="SM && showMobileMenu " >

       <v-sheet color="transparent" class="overflow-y-auto">
         <template v-for="(item, i) in menuItems" >

           <div :key="'mobile_menu' + i" >

             <v-btn block text :to="localeLink(item.route)" :color="wsACCENT"  @click="showMobileMenu = false" class="noCaps d-flex justify-space-between pointer  py-2 mb-1" >
               <h5 :style="`color : ${item.expand ? wsACCENT : '' }; font-size : 13px`" class="mr-5 text-no-wrap " >
                 {{ $t(item.text) }}
               </h5>
               <v-icon v-if="item.children"  :color="wsACCENT">mdi-menu-down</v-icon>
             </v-btn>
             <v-expand-transition>
               <div v-if="item.expand">
                 <div v-for="(child,j) in item.children" :key="'mobile_sub_item' + i + j">
                   <v-btn
                       @click="showMobileMenu = false"
                       :to="localeLink(child.route)"
                       :color="wsACCENT"
                       block text   class="noCaps pl-8 d-flex pointer justify-space-between py-2" >
                     <h5 style="color : black"  class="mr-5 text-no-wrap" >
                       {{ $t(child.text) }}
                     </h5>
                   </v-btn>
                 </div>
               </div>
             </v-expand-transition>
           </div>



         </template>

         <language-list  />

         <div class="py-2 pt-3 px-4">

           <template  v-if="!$store.state.auth.loggedIn">
             <ws-button
                 v-if="!$store.state.auth.loggedIn"
                 @click="showMobileMenu = false"
                 :to="localeLink('login')"
                 label="Login"
                 class="mr-3 mb-2"
                 outlined
                 block
             />
             <ws-button
                 v-if="!$store.state.auth.loggedIn"
                 @click="showMobileMenu = false"
                 :to="localeLink('register')"
                 block
                 label="Registration"
                 class="mb-3"
             />
           </template>

           <v-btn
               v-else
               @click="showMobileMenu=false; logout()"
               :color="wsACCENT"
               block
               outlined
               class="noCaps  d-flex pointer justify-space-between py-2"
           >
             <div class="mr-7" />
             <h5 :style="`color : ${ wsACCENT }`" class="mr-5 text-no-wrap" >
               {{ $t('Logout') }}
             </h5>
             <v-icon :color=" wsACCENT">mdi-logout</v-icon>
           </v-btn>

         </div>

       </v-sheet>

     </v-sheet>
   </v-expand-transition>


 </v-sheet>
</template>

<script>
import userDropdown from "@/components/UI/userDropdown";
import {mapMutations} from "vuex";
import wsLangSelect from "@/components/UI/wsLangSelect";
import languageList from "@/components/pages/homepage_westudy/UI/languageList";
export default {
  name: "homeHeader",
  components : {
    userDropdown,
    wsLangSelect,
    languageList
  },
  data() {
    return {
      showMobileMenu : false,
      menuItems : [],
      focused : false
    }
  },
  computed : {
    menuItemsData() {
      return [
        { text : 'Pricing'           , route : 'pricing'  },
        { text : 'Blog'              , route : 'news'     },
        { text : 'DocumentationPage' , route : 'documentation/westudy'  },
        { text : 'CheckCertificate'  , route : 'certificate/check'  },
        { text : 'Contacts'          , route : 'contacts'     },
      ]
    }
  },
  watch : {
    windowSize(val) {
     if ( val !== 'sm') { this.showMobileMenu = false }
    }
  },
  methods : {
    ...mapMutations(['toggleLoginMenu']),
    ...mapMutations('auth',['LOGOUT']),

    searchCourses($event) {
      if (!$event) {
        this.$router.push(this.localeLink(`market/courses`))
        return
      }
      const link = encodeURIComponent($event)
      this.$router.push(this.localeLink(`market/courses?search=${link}`))
    },

    logout() {
      this.LOGOUT(this.$router)
    },

    expandMobileSection(item) {
      item.expand = !item.expand
    },
    mobileOutsideClick() {
      if ( this.showMobileMenu === true ) {
        this.showMobileMenu = false;
      }
    }
  },
  mounted() {
    this.menuItems = this.menuItemsData
  }
}
</script>

<style lang="scss" scoped>
.mobile_menu {
  position: fixed;
  top: 64px ;
  left: 0;
  right : 0
}
</style>